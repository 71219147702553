import * as serialize from "serialize-javascript";
import cloneDeep from "lodash.clonedeep";
const booleani = new Set([
  "enabled",
  "active",
  "haveScheduledVariants",
  "enableAnalitica",
  "siteMapped",
  "logOperation",
  "paginateFromCache",
  "chooseIfFallback",
  "canBeFallback",
  "scheduled",
  "canBeSmart",
  "isPage",
  "userBased",
  "uselessMultiplier",
  "compliant",
  "cache",
  "trackInSession",
  "trackInUserSession",
  "running",
  "stuckUser",
  "injectHistory",
  "fullSubstituteFallback",
  "timeless",
  "includeContentIds",
  "default",
  "fetchUserMetadata",
  "writeOutput",
  "required",
  "useSimilarity",
  "onlyRecommendable",
  "historicized",
  "grouped",
  "patcher",
  "isUserBased"
]);
export const blockMetaNotFallback = [
  "similarity",
  "content",
  "collaborative",
  "user",
  "userSimilarity",
  "userBasedConversionPack",
  "geolocation",
  "extra",
  "schedule",
  "context",
  "params",
  "search"
];
export const scheduleOperators = [
  "dateRange",
  "range",
  "between",
  "greaterThan",
  "greaterEqualThan",
  "lessThan",
  "lessEqualThan"
];

const has = (k) => k !== null && k !== undefined;
const isTrue = (k) => k === true || k === 1;
const diverse = (a, b) => (!has(a) ? false : a !== b);
const pivotMap = (data, field) => {
  const ret = new Map();
  if (!data) return ret;

  data.forEach((e) => {
    const id = e[field];
    if (id) {
      if (!ret.has(id)) ret.set(id, [e]);
      else ret.set(id, [...ret.get(id), e]);
    }
  });

  return ret;
};

export const strip = (value) => String(value).replace(/"/g, "");
export const getScheduling = (data = {}, scheduling = []) => {
  if (data.and || data.or)
    return getScheduling(data.and || data.or, scheduling);
  data?.forEach?.((rule) => {
    if (scheduleOperators.includes(rule.operator)) {
      const range = rule.values[1].value ?? "";
      if (rule.values[0].value.includes("when_")) {
        if (range) {
          const [start, end] = strip(range).split("÷");
          scheduling.push({ id: rule.id, start, end });
        }
      }
    }
  });
  return [scheduling.length > 0, scheduling];
};

const updateBoolean = (elm) => {
  Object.entries(elm).forEach(([key, value]) => {
    if (
      (value === 1 || value === 0) &&
      !booleani.has(key) &&
      key !== "version"
    ) {
      console.log("⁕ FOUND NEW BOOLEAN KEY", key);
    }

    if (booleani.has(key)) {
      if (value === 1) elm[key] = true;
      if (value === 0) elm[key] = false;
    }
  });
};

class RefManager {
  constructor({ refs, db }) {
    this.refs = refs ?? new Map();
    this.db = db;
    this.propertyEndpointsCache = new Map();
    this.allMeta = new Set();
  }

  getRefMap() {
    return this.refs;
  }

  export() {
    return serialize.default(this.refs);
  }

  process(type, obj) {
    const child = { title: obj.title, scheduled: false, ...obj.refsComputed };
    const strObj = JSON.stringify(obj);
    const metadata = new Set();
    const catalogs = new Set();
    const label = new Set();
    const fallbacks = new Set();
    let isPage = false;

    if (obj.label) label.add(obj.label);
    if (obj.contentType) catalogs.add(obj.contentType);
    if (obj.metadataOverrides) {
      Object.values(obj.metadataOverrides).forEach((over) => {
        const meta = this.getMetadata(over);
        meta.forEach((m) => metadata.add(m));
      });
    }

    if (obj.useSimilarity) metadata.add("similarity");

    const checkParamsContext = (r) => {
      if (r.condition?.includes("query_") || r.value?.includes("query_"))
        metadata.add("search");
      if (r.condition?.includes("params_") || r.value?.includes("params_"))
        metadata.add("params");
      if (
        r.condition?.includes("geolocation_") ||
        r.value?.includes("geolocation_")
      ) {
        metadata.add("geolocation");
      }
      if (r.condition?.includes("context_") || r.value?.includes("context_")) {
        metadata.add("context");
      }
    };

    if (type === "endpoints") {
      child.cached = obj.params?.cacheConfig?.cache;
      child.analitica = obj.enableAnalitica ?? false;

      const meta = this.getMetadata(obj.variantsMetadata);
      meta.forEach((m) => metadata.add(m));
      const blocks = [obj.blockId];
      if (obj.variants) {
        obj.variants.forEach((v) => {
          blocks.push(v.blockId);
          checkParamsContext(v);
        });
      }

      child.blocks = blocks;

      const requireBlocks = obj.refsComputed?.require?.items
        ?.filter((r) => r.type === "blocks")?.[0]
        ?.items?.map((f) => f.id);

      child.blocksNotInRequire = blocks.filter(
        (b) => !requireBlocks?.includes(b)
      );
    }

    if (type === "blocks") {
      if (obj.isPage) isPage = true;
      if (obj.results?.fallbackBlockId)
        fallbacks.add(obj.results.fallbackBlockId);

      obj.blocks?.forEach((block) => {
        if (block.group) label.add(block.group);
        if (block.groupSub) label.add(block.groupSub);
        if (block.results?.contentType)
          catalogs.add(block.results?.contentType);
        if (block.results?.fallbackBlockId)
          fallbacks.add(block.results.fallbackBlockId);

        const [scheduled] = getScheduling(block.conditionRaw);
        if (scheduled) child.scheduled = true;

        checkParamsContext(block);
        const meta = this.getMetadata(block.metadata);
        meta.forEach((m) => metadata.add(m));
      });
    }

    if (type === "rules") {
      const meta = this.getMetadata(obj.metadata);
      meta.forEach((m) => metadata.add(m));
      obj.rule?.forEach((r) => {
        checkParamsContext(r);
        const [scheduled] = getScheduling(r.conditionRaw);
        if (scheduled) child.scheduled = true;
      });
    }

    child.useSmartBlocks = (() => {
      if (type === "blocks" && obj.blocks) {
        for (const block of obj.blocks) {
          if (block.type === "smart") return true;
        }
      }
      return false;
    })();

    child.isUserBased = (() => {
      if (type === "blocks" && obj.blocks) {
        for (const block of obj.blocks) {
          if (block.type === "smart") return true;
        }
        return false;
      } else {
        return (
          (strObj.includes(`"userContentType"`) &&
            !strObj.includes(`"required":false,"userContentType":`)) ||
          strObj.includes(`"userBased":1`) ||
          strObj.includes(`"userBased":true`)
        );
      }
    })();

    child.clauses = obj.clauses ?? [];
    child.enabledPagesId = obj.enabledPagesId ?? [];
    child.flatten = obj.results?.flatAllBlocks === true;
    child.canBeFallback = !blockMetaNotFallback.some((k) => metadata.has(k));
    child.isFallback = obj.canBeFallback ?? false;
    child.isSmart = obj.canBeSmart ?? false;
    child.useSimilarity = obj.useSimilarity ?? false;

    child.catalogs = [...catalogs];
    child.fallbacks = [...fallbacks];
    child.label = [...label];
    child.isPage = isPage;
    child.active = obj.active ?? 0;
    child.type = type;

    if (child.isUserBased) metadata.add("user");
    if (child.scheduled) metadata.add("schedule");

    child.metadata = [...metadata];

    child.metadata.forEach((m) => this.allMeta.add(m));

    if (obj.clause) child.clauses.push(obj.clause);
    if (obj.isDefault) child.isDefault = true;
    if (obj.realm) child.realm = obj.realm;
    if (obj.propertyId) child.propertyId = obj.propertyId;
    if (obj.reference) child.uxReference = obj.reference;
    if (obj.language) child.language = obj.language;
    if (["decorators", "lists", "labels"].includes(type) && obj.type)
      child.objectType = obj.type;

    child.isInProperty = obj.propertyId !== undefined;
    child.id = obj.id;
    child.description = obj.description;

    return child;
  }

  add(type, obj) {
    updateBoolean(obj);
    if (!this.refs.has(type)) this.refs.set(type, new Map());
    this.refs.get(type).set(obj.id, this.process(type, obj));
  }

  update(data) {
    data = Array.isArray(data) ? data : [data];
    for (const { id, type, update } of data) {
      if (this.refs.has(type)) {
        const obj = this.refs.get(type).get(id);
        if (obj) {
          this.refs.get(type).set(id, { ...obj, ...update });
        }
      }
    }
  }

  delete(type, id) {
    if (this.refs.has(type)) {
      if (this.refs.get(type).has(id)) {
        this.refs.get(type).delete(id);
      }
    }
  }

  getWarnings(info) {
    const warns = [];

    if (info) {
      if (info.type === "blocks") {
        if (!info.canBeFallback && info.isUsedAsFallback)
          warns.push("This Item cannot be a Fallback but is used as Fallback");

        if (!info.canBeFallback && info.isUsedInSmartBlockContainer)
          warns.push(
            "This Item cannot be a Fallback but is used in Smart Block Container"
          );

        if (info.canBeFallback && info.scheduled)
          warns.push(
            "This Item cannot be a Fallback because is Scheduled but it can"
          );

        if (
          !info.isSmart &&
          info.isUsedInSmartBlockContainer &&
          !info.isUsedAsFallback
        )
          warns.push("This is used in Smart Block Container but is not Smart");

        if (!info.canBeFallback && info.isFallback)
          warns.push(
            "This Item cannot be a Fallback but is marked as Fallback"
          );

        if (!info.canBeFallback && info.isSmart)
          warns.push("This Item cannot be a Fallback but is marked as Smart");

        if (info.canBeFallback && info.isUserBased)
          warns.push("This Item is User based but it can be a Fallback");

        if (info.isUsedAsFallback && info.isUserBased)
          warns.push("This Item is User based but is used as Fallback");
      }

      if (info.isInFallback && !info.canBeFallback)
        warns.push("This item is inside a Fallback but it cannot");

      if (info.uselessMultiplier)
        warns.push("This item use a Useless Multiplier");
    }

    return warns.length > 0 ? warns : null;
  }

  getAllWarnings() {
    const warns = new Map();
    const all = [];

    this.refs.forEach((values, table) => {
      if (!warns.has(table)) warns.set(table, new Map());

      values.forEach((obj, id) => {
        const info = this.getItem(table, obj);
        if (info.warnings) {
          warns.get(table).set(id, info);
          all.push(info);
        }
      });
    });

    for (const key of warns.keys()) {
      if (warns.get(key).size === 0) warns.delete(key);
    }
    return [warns, all];
  }

  getAllMetadata() {
    return [...this.allMeta];
  }

  getPropertyEndpoints(propertyId) {
    if (this.propertyEndpointsCache.has(propertyId)) {
      return this.propertyEndpointsCache.get(propertyId);
    }

    const endpoints = this.refs.has("endpoints")
      ? [...this.refs.get("endpoints").values()].filter(
          (k) => k.propertyId === propertyId
        )
      : [];

    this.propertyEndpointsCache.set(propertyId, endpoints);
    return endpoints;
  }

  buildTitlePath(obj) {
    if (obj.propertyId) {
      if (this.refs.has("properties")) {
        if (this.refs.get("properties").has(obj.propertyId)) {
          const property = this.refs.get("properties").get(obj.propertyId);

          let titlePath;
          let titlePathRef;

          if (obj.type === "blocks") {
            const endpoints = this.getPropertyEndpoints(obj.propertyId);
            for (const endpoint of endpoints) {
              if (endpoint.blocks) {
                const isIn =
                  endpoint.blocks.findIndex((sub) => sub === obj.id) > -1;
                if (isIn) {
                  titlePath = [property.title, endpoint.title, obj.title];
                  titlePathRef = [property.id, endpoint.id, obj.id];
                  break;
                }
              }
            }
          }

          if (obj.type === "endpoints") {
            const defaultId = this.db.get("endpoints", obj.id).blockId;

            titlePath = [property.title, obj.title];
            titlePathRef = [property.id, obj.id, defaultId];
          }

          if (titlePath) {
            obj.titlePath = titlePath;
            obj.titlePathRef = titlePathRef;
          }
        }
      }
    }
    return obj;
  }

  getAllRelationObj(
    subject,
    relation = "belongs",
    tree = new Map(),
    originId,
    includeSubject = false
  ) {
    const { type, id } = subject;
    let isInLoop = false;

    if (!originId) originId = id;

    if (includeSubject) {
      const me = { relation, type, id: subject.id, title: subject.title };
      me.metadata = subject?.metadata ?? [];
      me.isPage = subject?.isPage ?? false;
      me.propertyId = subject?.propertyId;
      me.isFallback = subject?.isFallback;
      me.canBeFallback = subject?.canBeFallback;
      me.isSmart = subject?.isSmart;
      me.isUserBased = subject?.isUserBased;
      me.useSmartBlocks = subject?.useSmartBlocks;
      me.scheduled = subject?.scheduled;
      me.clauses = subject?.clauses ?? [];
      me.fallbacks = subject?.fallbacks ?? [];
      me.useSimilarity = subject?.useSimilarity ?? false;

      tree.set(subject.id, me);
    }

    if (subject[relation]) {
      for (const { items } of subject[relation].items) {
        for (const entity of items ?? []) {
          if (tree.has(entity.id)) continue;

          const itemId = entity.id ?? entity.variantId;

          if (itemId === originId) {
            isInLoop = true;
            continue;
          } else {
            if (entity.type && entity.id !== originId) {
              if (!this.refs.has(entity.type)) continue;

              const obj = this.refs.get(entity.type).get(entity.id);
              this.getAllRelation(entity.type, entity.id, relation, tree);

              const child = {
                relation,
                type: entity.type,
                id: entity.id,
                title: obj?.title
              };

              child.metadata = obj?.metadata ?? [];
              child.isPage = obj?.isPage ?? false;
              child.propertyId = obj?.propertyId;
              child.isFallback = obj?.isFallback;
              child.canBeFallback = obj?.canBeFallback;
              child.isSmart = obj?.isSmart;
              child.isUserBased = obj?.isUserBased;
              child.useSmartBlocks = obj?.useSmartBlocks;
              child.scheduled = obj?.scheduled;
              child.clauses = obj?.clauses ?? [];
              child.fallbacks = obj?.fallbacks ?? [];
              child.useSimilarity = obj?.useSimilarity ?? false;

              if (obj?.objectType) child.objectType = obj.objectType;
              if (obj?.realm) child.realm = obj.realm;

              tree.set(entity.id, child);
            } else {
              if (entity.variantId) {
                this.getAllRelation(
                  "blocks",
                  entity.variantId,
                  relation,
                  tree,
                  originId,
                  true
                );
                this.getAllRelation(
                  "properties",
                  entity.propertyId,
                  relation,
                  tree,
                  originId,
                  true
                );
                this.getAllRelation(
                  "endpoints",
                  entity.endpointId,
                  relation,
                  tree,
                  originId,
                  true
                );
              }
            }
          }
        }
      }
    }

    const metadata = new Set(subject?.metadata);
    const fallbacks = new Set();

    const re = {
      type,
      id,
      title: subject?.title,
      relation,
      isInLoop,
      canBeFallback: subject?.canBeFallback ?? true,
      isUserBased: subject?.isUserBased ?? false,
      isInFallback: false,
      isUsedInSmartBlockContainer: false,
      isInPage: subject?.isInPage ?? false,
      isPage: subject?.isPage,
      useSmartBlocks: subject?.useSmartBlocks ?? false,
      scheduled: subject?.scheduled ?? false,
      useSimilarity: subject?.useSimilarity ?? false,
      ...(subject?.propertyId && { propertyId: subject.propertyId })
    };

    const treeArray = [...tree.values()];

    treeArray.forEach((t) => {
      if (t.metadata) {
        t.metadata.forEach((m) => metadata.add(m));
      }
      t.fallbacks.forEach((f) => fallbacks.add(f));

      if (t.useSmartBlocks) {
        // isUsed is for Belongs -> useSmartBlocks is for require
        re.isUsedInSmartBlockContainer = true;
        re.useSmartBlocks = true;
      }
      if (t.scheduled) re.scheduled = true;

      // REQUIRES
      if (has(t.canBeFallback) && t.canBeFallback === false)
        re.canBeFallback = false;
      if (has(t.isUserBased) && t.isUserBased === true) re.isUserBased = true;

      // BELONGS
      if (has(t.isFallback) && isTrue(t.isFallback)) re.isInFallback = true;
      if (has(t.isPage) && isTrue(t.isPage)) re.isInPage = true;
    });

    re.isUsedAsFallback = relation === "belongs" ? fallbacks.has(id) : false;
    re.metadata = [...metadata];
    re.relations = treeArray;

    delete re.fallbacks;
    return re;
  }

  getAllRelation(
    type,
    id,
    relation = "belongs",
    tree,
    originId,
    includeSubject
  ) {
    let subject;

    if (this.refs.has(type)) {
      if (this.refs.get(type).has(id)) {
        subject = this.refs.get(type).get(id);

        return this.getAllRelationObj(
          subject,
          relation,
          tree,
          originId,
          includeSubject
        );
      }
    }
  }

  getItem(type, obj, filters = {}) {
    const {
      isFallback,
      isPage,
      isInFallback,
      isInPage,
      canBeFallback,
      isUserBased,
      metadata
    } = filters;
    const id = obj.id;

    if (filters.id && filters.id !== id) return;
    if (filters.ids && !filters.ids.includes(id)) return;
    if (filters.property && filters.property !== obj.propertyId) return;
    if (filters.exclude && filters.exclude.includes(id)) return;
    if (filters.labels && !filters.labels.every((k) => obj.label.includes(k)))
      return;
    if (
      filters.clauses &&
      !filters.clauses.some((k) => obj.clauses.includes(k))
    )
      return;
    if (filters.catalogs) {
      if (filters.catalogs !== "*" || !filters.catalogs.includes("*")) {
        if (!obj.catalogs.some((k) => filters.catalogs.includes(k))) return;
      }
    }
    if (
      filters.title &&
      !obj.title.toLowerCase().includes(filters.title.toLowerCase())
    )
      return;
    if (has(filters.flatten) && filters.flatten !== obj.flatten) return;
    if (
      has(filters.objectTypes) &&
      !filters.objectTypes.includes(obj.objectType)
    )
      return;
    if (has(filters.realms) && !filters.realms.includes(obj.realm)) return;
    if (has(filters.isDefault) && filters.isDefault !== obj.isDefault) return;
    if (has(filters.isSmart) && filters.isSmart !== obj.isSmart) return;
    if (has(filters.scheduled) && filters.scheduled !== obj.scheduled) return;
    if (has(filters.active) && filters.active !== obj.active) return;
    if (has(filters.analitica) && filters.analitica !== obj.analitica) return;
    if (has(filters.isInProperty) && filters.isInProperty !== obj.isInProperty)
      return;
    if (
      filters.enabledPageId &&
      !obj.enabledPagesId.includes(filters.enabledPageId)
    )
      return;

    const cumulativeMetadata = new Set();
    let include = true;
    const belongs = this.getAllRelationObj(obj, "belongs");
    const require = this.getAllRelationObj(obj, "require");

    // COLLECT META
    [require].forEach((m) => {
      m.relations.forEach((r) => {
        if (r.metadata) {
          r.metadata.forEach((meta) => cumulativeMetadata.add(meta));
        }
      });
    });

    // ITEMS
    if (has(isFallback) && diverse(obj.isFallback, isFallback)) include = false;
    if (has(isPage) && diverse(obj.isPage, isPage)) include = false;

    // REQUIRES
    if (has(canBeFallback) && diverse(require.canBeFallback, canBeFallback))
      include = false;
    if (has(isUserBased) && diverse(require.isUserBased, isUserBased))
      include = false;

    // BELONGS
    if (has(isInFallback) && diverse(belongs.isInFallback, isInFallback))
      include = false;
    if (has(isInPage) && diverse(belongs.isInPage, isInPage)) include = false;

    // METADATA
    const objMetadata = new Set([...cumulativeMetadata, ...obj.metadata]);
    if (has(metadata)) {
      if (objMetadata.size === 0) include = false;
      else {
        const meta = Array.isArray(metadata) ? metadata : [metadata];
        if (!meta.every((k) => objMetadata.has(k))) include = false;
      }
    }

    if (include) {
      obj = cloneDeep(obj);
      this.buildTitlePath(obj);
      obj.loopIds = belongs.relations
        .filter((obj) => obj.type === type)
        .map(({ id }) => id);

      // Add Required Smart Block to loopId List to avoid nesting
      if (obj.useSmartBlocks) {
        const ids = require.relations
          .filter((obj) => obj.type === type && obj.isSmart)
          .map(({ id }) => id);
        obj.loopIds = [...obj.loopIds, ...ids];
      }

      obj.metadata = [...objMetadata];
      obj.useSmartBlocks = obj.useSmartBlocks || require.useSmartBlocks;
      obj.scheduled = obj.scheduled || require.scheduled;

      obj.canBeFallback = (() => {
        if (!obj.canBeFallback) return false;
        if (obj.useSmartBlocks) return false;
        if (obj.useSimilarity) return false;
        if (obj.scheduled) return false;
        return require.canBeFallback;
      })();
      obj.isInFallback = belongs.isInFallback;
      obj.isUsedAsFallback = belongs.isUsedAsFallback;
      obj.isUsedInSmartBlockContainer = belongs.isUsedInSmartBlockContainer;

      obj.isInPage = belongs.isInPage;

      obj.isInLoop = require.isInLoop || belongs.isInLoop;
      obj.belongsTree = belongs.relations.map((obj) =>
        this.buildTitlePath(obj)
      );
      obj.requireTree = require.relations.map((obj) =>
        this.buildTitlePath(obj)
      );

      obj.belongsSmartBlockContainers = belongs.relations
        .filter((r) => r.useSmartBlocks)
        .map((obj) => this.buildTitlePath(obj));
      obj.usedInFallback = belongs.relations
        .filter((r) => r.isFallback)
        .map((obj) => this.buildTitlePath(obj));
      obj.usedAsFallback = belongs.relations
        .filter((r) => r.fallbacks.includes(obj.id))
        .map((obj) => this.buildTitlePath(obj));
      obj.requireFallbacks = require.relations
        .filter((r) => r.isFallback)
        .map((obj) => this.buildTitlePath(obj));
      obj.requireScheduled = require.relations
        .filter((r) => r.scheduled)
        .map((obj) => this.buildTitlePath(obj));

      obj.canDelete = (() => {
        if (
          obj.type === "endpoints" &&
          obj.requireFallbacks.filter((r) => obj.blocks.includes(r.id)).length >
            0
        )
          return false;

        if (obj.type === "blocks" && obj.isUsedAsFallback) return false;
        if (obj.type === "endpoints" && obj.belongs?.total > 0) return false; // ViewAll in Properties

        return true;
      })();

      obj.restricted =
        obj.isFallback ||
        obj.isInFallback ||
        obj.isUsedAsFallback ||
        obj.isSmart ||
        obj.isUsedInSmartBlockContainer;

      obj.warnings = this.getWarnings(obj);

      return obj;
    }
  }

  getItemStatic(type, id) {
    if (this.refs.has(type)) {
      if (this.refs.get(type).has(id)) {
        const obj = this.refs.get(type).get(id);
        const info = this.getItem(type, obj);

        return info;
      }
    }

    return {};
  }

  getAll(type, filters = {}) {
    const response = [];

    if (this.refs.has(type)) {
      for (const obj of this.refs.get(type).values()) {
        const o = this.getItem(type, obj, filters);
        if (o) response.push(o);
      }
    }

    return response;
  }

  getAllKW(type, filters = {}) {
    return this.getAll(type, filters)
      .map((obj) => ({
        key: obj.id,
        value: obj.reference
          ? obj.reference
          : obj.titlePath
          ? obj.titlePath.join(" ▸ ")
          : obj.title
      }))
      .sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
  }

  getAllIds(type, filters = {}) {
    return this.getAll(type, filters).map((obj) => obj.id);
  }

  getMetadata(metadata = {}) {
    return Object.entries(metadata)
      .filter(([metadataName, value]) => {
        if (value.required !== undefined) {
          // *******************************************************
          // PATCH: to  let content_similar with no similarity
          // fields set to be fallbackable (not content dependent)
          // *******************************************************
          if (metadataName === "content_similar") {
            if (value.required === false) return false;

            if (value.fingerprint?.matchFields?.length) {
              return true;
            }

            for (const key of ["included", "excluded"]) {
              const accessor = value.metadata?.[key];
              if (accessor) {
                for (const meta of accessor) {
                  const split = meta.split("=");
                  if (split.length === 1) return true;
                }
              }
            }

            if (value.date?.range > 0 && !value.date?.absolute) return true;

            return false;
          }
          if (metadataName === "conversionPacks") {
            if (value.userBased && value.required) return true;
            return false;
          }

          if (
            metadataName === "contentPacks" &&
            value.required &&
            value.packs
          ) {
            for (const pack of value.packs) {
              const { id, override } = pack;
              if (override) {
                const over = JSON.parse(override);
                if (over.useSimilarity) return true;
              } else {
                const item = this.db.get("contentPacks", id);
                if (item.useSimilarity) return true;
              }
              return false;
            }
          }
          return value.required;
        }
        return value;
      })
      .map(([key, _]) => {
        if (key === "content_similar" || key === "contentPacks")
          return "similarity";
        if (key === "user_similar") return "userSimilarity";
        if (key === "conversionPacks") return "userBasedConversionPack";
        return key;
      });
  }

  checkQueriesHealth() {
    if (this.db) {
      console.time(`🎱 CHECK-QUERIES-HEALTH`);
      const querySet = new Set();

      this.db.getTable("blocks").forEach((subs) => {
        subs.blocks.forEach((block) => {
          if (block.queryId) {
            const f = block.results?.filters || {};

            if (
              f.repeatedMetadata?.length ||
              f.scripts?.length ||
              f.alreadyConsumed ||
              f.alreadyRecommendedMoreThen
            ) {
              querySet.add(block.queryId);
            }
          }
        });
      });

      const updates = [];

      this.db.getTable("queries").forEach((query) => {
        const k = query.general.resultsMultiplier;

        if (k > 1 && !querySet.has(query.id)) {
          updates.push({
            id: query.id,
            type: "queries",
            update: { uselessMultiplier: true }
          });
        }
      });

      this.update(updates);
      console.timeEnd(`🎱 CHECK-QUERIES-HEALTH`);
    }
  }

  fillBelongs(data, subset, table, addToRefs = true) {
    if (this.db) {
      const blocksMap = this.db.getTable("blocks");
      const listsMap = this.db.getTable("lists");
      const endpointsMap = pivotMap(
        this.db.getTable("endpoints"),
        "propertyId"
      );
      const postBlocksBelongsMap = new Map();

      const addBlockBelong = (id, required) => {
        required.forEach((blockId) => {
          if (!postBlocksBelongsMap.has(blockId))
            postBlocksBelongsMap.set(blockId, []);
          const items = [
            ...new Set([...postBlocksBelongsMap.get(blockId), id])
          ];
          postBlocksBelongsMap.set(blockId, items);
        });
      };

      const packBlocks = (b) => {
        const pack = [];
        const blocks = Array.isArray(b) ? b : [...b];

        blocks.forEach((id) => {
          const block = blocksMap.get(id);
          if (block) {
            pack.push({ id, type: "blocks", title: block.title });
          }
        });

        return pack;
      };

      const getTitle = (key, id) => {
        const type = data.get(key);
        if (!type) return "";
        const sub = Array.isArray(type) ? type : Object.values(type);

        return sub.find((item) => item.id === id)?.title;
      };

      for (let [key, value] of (subset || data).entries()) {
        if (!key) key = table;
        const sub = Array.isArray(value) ? value : Object.values(value);
        if (addToRefs) console.time(`💉 FILL-BELONGS-${key.toUpperCase()}`);

        for (const item of sub) {
          if (!item || !item.refs) {
            if (addToRefs) {
              this.add(key, { id: item?.id, title: item?.title });
            }
            continue;
          }

          let totalBelongs = 0;
          const belongings = [];

          const bels = item.refs?.belongs
            ? Array.isArray(item.refs.belongs)
              ? item.refs.belongs
              : [item.refs.belongs]
            : [];

          // BELONGS
          for (let { type, items: elementi } of bels) {
            let items = [...elementi];
            const pages = [];
            const count = items?.length ?? 0;
            totalBelongs += count;

            if (type === "blocks") {
              // Substitute Nested Blocks with Pages
              const ids = items?.map((id) => id);
              const blocks = ids.map((id) => blocksMap.get(id));
              const filterBlocks = new Set();

              for (let k = 0, length = blocks?.length ?? 0; k < length; k++) {
                const block = blocks[k];

                if (block) {
                  // if (block.blocks.length === 1 && block.blocks[0].type === "nested") {
                  if (block.isPage) {
                    const property = endpointsMap.get(block.propertyId);
                    if (!property) continue;

                    const endpoint = property.find((e) => {
                      if (e.blockId === block.id) return true;
                      return e.variants.some((v) =>
                        v?.blockId?.includes?.(block.id)
                      ); //.map
                    });

                    if (endpoint) {
                      pages.push({
                        variantId: block.id,
                        variantTitle: getTitle("blocks", block.id),
                        propertyId: block.propertyId,
                        propertyTitle: getTitle("properties", block.propertyId),
                        endpointId: endpoint.id,
                        endpointTitle: getTitle("endpoints", endpoint.id)
                      });
                    }
                    filterBlocks.add(block.id);
                  }
                }
              }

              items = items.filter((id) => !filterBlocks.has(id));
            }

            if (pages?.length > 0) {
              belongings.push({
                type: "pages",
                count: pages?.length ?? 0,
                items: pages
              });
            }

            if (items?.length > 0) {
              belongings.push({
                type,
                count,
                items: items?.map((id) => ({
                  id,
                  type,
                  title: getTitle(type, id)
                }))
              });
            }
          }

          // REQUIRES
          let totalRequire = 0;
          const requires = [];

          const reqs = item.refs?.require
            ? Array.isArray(item.refs.require)
              ? item.refs.require
              : [item.refs.require]
            : [];

          for (const { type, items } of reqs) {
            const count = items?.length ?? 0;
            totalRequire += count;
            requires.push({
              type,
              count,
              items: items?.map((id) => ({
                id,
                type,
                title: getTitle(type, id)
              }))
            });
          }

          // ********************************************
          // SMART BLOCKS REQUIRES (List)
          // Get alla blocks Id in linked Smart List
          // Ad do requires + post belongs
          // ********************************************
          if (key === "blocks" && item.blocks) {
            const smartBlocks = new Set();

            item.blocks.forEach((block) => {
              if (block.type === "smart" && block.smartPageListId) {
                const lista = listsMap.get(block.smartPageListId);
                if (lista && lista.elements?.length > 0) {
                  lista.elements.forEach((id) => smartBlocks.add(id));
                }
              }
            });

            const count = smartBlocks.size;
            if (count > 0) {
              totalRequire += count;
              const index = requires.findIndex(({ type }) => type === "blocks");
              if (index > -1) {
                const base = requires[index];
                const items = [
                  ...new Set([...base.items, ...packBlocks(smartBlocks)])
                ];
                requires[index] = { ...base, count: base.count + count, items };
              } else {
                requires.push({
                  type: "blocks",
                  count,
                  items: packBlocks(smartBlocks)
                });
              }

              addBlockBelong(item.id, smartBlocks);
            }
          }

          const ref = {
            belongs: {
              total: totalBelongs,
              types: belongings?.length,
              items: belongings
            },
            require: {
              total: totalRequire,
              types: requires?.length,
              items: requires
            }
          };

          item.refsComputed = ref;
          if (addToRefs) this.add(key, item);
        }
        if (addToRefs) console.timeEnd(`💉 FILL-BELONGS-${key.toUpperCase()}`);
      }

      // ********************************************
      // SMART BLOCKS BELONGS (List)
      // Fill calculate smart block belongs
      // ********************************************
      if (postBlocksBelongsMap.size > 0 && data.has("blocks")) {
        const blocks = data.get("blocks");
        for (const [id, _ib] of postBlocksBelongsMap.entries()) {
          const block = blocks[id];
          if (block) {
            const itemsBelongs = packBlocks(_ib);
            const count = itemsBelongs?.length;
            const belongs = block.refsComputed.belongs;
            const index = belongs.items.findIndex(
              ({ type }) => type === "blocks"
            );
            if (index > -1) {
              const base = belongs.items[index];
              const items = [...new Set([...base.items, ...itemsBelongs])];
              belongs.items[index] = {
                ...base,
                count: base.count + count,
                items
              };
            } else {
              belongs.items.push({
                type: "blocks",
                count,
                items: itemsBelongs
              });
              belongs.types += 1;
            }
            belongs.total += count;
          }
        }
      }

      return data;
    }
  }
}

export default RefManager;

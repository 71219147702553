import { Fragment, useCallback, useState, useMemo, useEffect } from "react";
import { useFormikContext } from "formik";
import { useItemContext } from "../../../hooks/useItem";
import useContentRealm from "../../../hooks/useContentRealm";
import { useSelectMem } from "../../../hooks/useMemoryDB";
import { Dropdown } from "react-bootstrap";

import { Virtuoso } from "react-virtuoso";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import { filterTypes } from "../../multipleValues";
import { db } from "../../../db";
import Select from "../selectWithSearch";
import stubs from "../../../utils/stubs";
import groupBy from "../../../utils/groupBy";
import "./style.scss";
import isNil from "lodash.isnil";

const simplifyTypes = (types, fieldTypes) => {
  const simply = new Set();
  let simplifyMetadata = true;

  if (types.includes("between")) simply.add("number");
  if (types.includes("date") || types.includes("number")) {
    simply.add("date");
    simply.add("number");
    simplifyMetadata = false;
  }

  if (
    types.includes("date") ||
    types.includes("range") ||
    types.includes("DateRange")
  ) {
    simply.add("date");
    simplifyMetadata = false;
  }

  if (types.includes("list") && !types.includes("metadata")) {
    simply.add("list");
  }

  if (types.includes("string")) simply.add("string");
  if (types.includes("metadata") && simplifyMetadata) simply.add("string");
  if (types.includes("number")) simply.add("number");
  if (types.includes("boolean")) simply.add("boolean");

  let simplified = [...simply];

  if (fieldTypes) {
    simplified = filterTypes(fieldTypes, simplified);
  }
  return simplified;
};

const none = (
  <Dropdown.Item key={`empty-seg`} value={""} style={{ height: "12px" }}>
    {" "}
  </Dropdown.Item>
);

function Group({ data, head, itemSize, width, maxHeight, foldable }) {
  const [open, setOpen] = useState();
  const catalog = data?.[0]?.catalog;
  const itemsCount = data.length;
  const height =
    itemsCount * itemSize > maxHeight ? maxHeight : itemsCount * itemSize;
  const icon = foldable ? (
    <FontAwesomeIcon icon={open ? faChevronDown : faChevronRight} fixedWidth />
  ) : null;

  const drawItem = useCallback(
    (index) => {
      const item = data[index];

      return (
        <Dropdown.Item
          eventKey={item.id}
          key={`${item.id}-${index}`}
          value={item.id}
          className="select-item-result"
          onClick={() => true}
        >
          {item.id}
        </Dropdown.Item>
      );
    },
    [data]
  );

  useEffect(() => {
    setOpen(!foldable);
  }, [foldable]);

  return (
    <Fragment key={`drop-frag-${head}-${catalog}`}>
      <Dropdown.Header
        className="drop-header group-header"
        key={`drop-header-${head}`}
        onClick={() => setOpen((state) => !state)}
      >
        {icon} {head}{" "}
        <span className="group-items-count-badge">{itemsCount}</span>
      </Dropdown.Header>
      {open ? (
        <Virtuoso
          style={{ height }}
          totalCount={itemsCount}
          itemContent={drawItem}
          className="sub-meta-list"
        />
      ) : null}

      <div className="drop-divider" />
    </Fragment>
  );
}

const asArray = (a) => (Array.isArray(a) ? a : [a]);
const filterDefault = (array) => array;
const prefixExcludes = ["context_", "params_", "query_"];
const emptyArray = [];

const SelectMeta = function SelectMeta(props) {
  const {
    name,
    value,
    disabled,
    catalogs,
    types,
    fieldTypes,
    excludes,
    excludeNested = false,
    compactTypes = true,
    realms,
    errored,
    handleChange,
    onMetaChangeType,
    filtro = filterDefault,
    style,
    className,
    info
  } = props;
  const { getRealm } = useContentRealm();
  const computedRealms = useMemo(() => {
    const fields = ["content"]; //["content", "collaborative", "user_similar"];

    if (fields.some((k) => realms.includes(k))) {
      const cats = Array.isArray(catalogs) ? catalogs : [catalogs];
      const superSet = new Set(realms || []);

      fields.forEach((field) => superSet.delete(field));

      cats.forEach((catalog) => {
        const realm = getRealm(catalog);

        if (realm) {
          if (realm === "content") {
            if (realms.includes("content")) superSet.add(realm);
          } else superSet.add(realm);
        }
      });

      return [...superSet];
    }

    return realms;
  }, [catalogs, getRealm, realms]);

  const { itemId } = useItemContext() || stubs.item;
  const { setFieldValue } = useFormikContext() || stubs.formik;
  const [filter, setFilter] = useState();
  //const getMetadata = useGetMemItem("metadata", null, true);
  const mtdGrouped = groupBy(
    filtro(
      useSelectMem(
        "metadata",
        useCallback(
          (i) => {
            if (!computedRealms) return false;
            if (!computedRealms.includes(i.realm)) return false;
            if (value && i.id === value) return false;
            if (excludes?.length && excludes.includes(i.id)) return false;
            if (itemId && !["*", itemId].includes(i.owner)) return false;
            if (catalogs && !["*", ...asArray(catalogs)].includes(i.catalog))
              return false;
            if (filter && !i.id.toLowerCase().includes(filter.toLowerCase()))
              return false;
            if (excludeNested && !(i.dbNestedField === 0)) return false;
            if (types?.length && types !== "*") {
              const tipi = compactTypes
                ? simplifyTypes(types, fieldTypes)
                : types;

              if (!i.types.some((k) => tipi.includes(k))) return false;
            }

            return true;
          },
          [
            catalogs,
            compactTypes,
            computedRealms,
            excludeNested,
            excludes,
            fieldTypes,
            filter,
            itemId,
            types,
            value
          ]
        )
      )
    ),
    ["realm", "catalog"],
    ["*"],
    info?.restricted ? prefixExcludes : emptyArray
  );

  const onSelect = useCallback(
    (value) => {
      if (isNil(value))
        handleChange ? handleChange("") : setFieldValue(name, "");
      else {
        const meta = [...db.getTable("metadata").values()].find(
          (m) => ["*", ...catalogs].includes(m.catalog) && m.id === value
        );
        handleChange ? handleChange(value) : setFieldValue(name, value);
        onMetaChangeType && onMetaChangeType(meta.types);

        // getMetadata(value, (meta) => {
        //   handleChange ? handleChange(value) : setFieldValue(name, value);
        //   onMetaChangeType && onMetaChangeType(meta.types);
        // });
      }
    },
    [catalogs, handleChange, name, onMetaChangeType, setFieldValue]
  );

  const metadataValues = useMemo(() => {
    if (mtdGrouped) {
      const groups = Object.entries(mtdGrouped);
      const foldable = filter?.length > 0 ? false : groups.length > 1;

      return [
        none,
        ...groups.map(([head, filtered]) => (
          <Group
            key={head}
            data={filtered}
            head={head}
            itemSize={26}
            maxHeight={170}
            width={380}
            foldable={foldable}
          />
        ))
      ];
    }
  }, [filter, mtdGrouped]);

  return (
    <Select
      key={`select-meta-${name}`}
      name={name}
      value={value}
      disabled={disabled}
      canFilter={true}
      errored={errored}
      onFilter={setFilter}
      onSelect={onSelect}
      style={style}
      className={className}
      info={{ prefix: info?.restricted ? prefixExcludes : emptyArray }}
    >
      {metadataValues}
    </Select>
  );
};

export default SelectMeta;

import {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Form,
  Button,
  ButtonGroup,
  NavDropdown,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { TabPane } from "rc-tabs";
import DraggableTabs from "../draggableTabs";
import pDebounce from "p-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import {
  faCopy,
  faTrashAlt,
  faClipboard,
  faClone
} from "@fortawesome/free-regular-svg-icons";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
import useClipboard from "../../hooks/useClipboard";
import { move } from "../../utils/array";
import cloneDeep from "lodash.clonedeep";
import niceTry from "nice-try";

import InputPersonas from "../personas/inputPersonas";
import Container from "../container";
import styles from "./styles.module.scss";
import "./global.scss";
import { explorer } from "../../transport";

const getRawSet = (tableData) => {
  const currentSet = {};
  Object.keys(tableData).forEach((key) => {
    const row = tableData[key];
    if (row.active === true) currentSet[key] = row.value;
  });

  return currentSet;
};

export default function RequestBox(props) {
  const {
    style,
    parameters,
    onSaveTab,
    onChange,
    onChangeDataSet,
    onChangeCurrentSet,
    onChangeTab,
    extra,
    excludeBasic = true,
    includeParams = true,
    compactDerived = true,
    includeNavigation = true,
    allowCollection = true,
    forceOpen = false,
    debounceWait = 200
  } = props;

  const data = useMemo(
    () =>
      parameters || {
        current: "Default",
        currentIndex: 0,
        sets: [{ title: "Default", params: {} }]
      },
    [parameters]
  );

  const { tenant, id_token } = useSelector((state) => state.user);
  const [fold, setFold] = useState(() => (forceOpen ? false : true));
  const [params, setParams] = useState();
  const [currentTabId, setCurrentTabId] = useState(data.current);
  const { clipboard, copy } = useClipboard();

  useEffect(() => {
    if (parameters) {
      setCurrentTabId(parameters.current);
      if (onChangeCurrentSet) {
        onChangeCurrentSet(
          getRawSet(parameters.sets[parameters.currentIndex].params)
        );
      }
    }
  }, [onChangeCurrentSet, parameters]);

  const debounceSave = useRef(
    pDebounce(
      (data, onSaveTab, onChangeDataSet, onChangeCurrentSet, onChange) => {
        const currentSet = getRawSet(data.sets[data.currentIndex].params);

        if (onChangeDataSet) {
          onChangeDataSet(data);
        }

        if (onChangeCurrentSet) {
          onChangeCurrentSet(currentSet);
        }
        if (onSaveTab) {
          onSaveTab(data, currentSet);
        }

        if (onChange) {
          onChange({ uiParams: data, currentParams: currentSet });
        }
      },
      debounceWait
    )
  );

  const callSaveTable = useCallback(
    (data) => {
      debounceSave.current(
        data,
        onSaveTab,
        onChangeDataSet,
        onChangeCurrentSet,
        onChange
      );
    },
    [onChange, onChangeCurrentSet, onChangeDataSet, onSaveTab]
  );
  const notifyChangeTab = useCallback(
    (ref, index, data) => {
      data.currentIndex = index;
      data.current = ref;

      if (ref !== currentTabId) {
        setCurrentTabId(ref);
        if (onChangeTab) onChangeTab(ref, index, data);
      }

      callSaveTable(data);
    },
    [callSaveTable, currentTabId, onChangeTab]
  );

  const addTab = useCallback(() => {
    const newData = { ...data };
    const currentIndex = data.sets.length;
    const ref = `Params Set #${currentIndex}`;

    newData.currentIndex = currentIndex;
    newData.current = ref;

    newData.sets.push({ title: ref, params: {} });

    notifyChangeTab(ref, currentIndex, newData);
  }, [data, notifyChangeTab]);

  const deleteTab = useCallback(
    (index) => {
      const newData = { ...data };

      newData.sets.splice(index, 1);

      const refIndex = newData.sets.findIndex(
        (set) => set.title === currentTabId
      );
      const currentIndex = refIndex > 0 ? refIndex : 0;

      newData.currentIndex = currentIndex;

      if (refIndex < 0) {
        newData.current = newData.sets[0].title;
      }

      notifyChangeTab(newData.current, newData.currentIndex, newData);
    },
    [currentTabId, data, notifyChangeTab]
  );

  const duplicateTab = useCallback(
    (index) => {
      const newData = { ...data }; //cloneDeep(data);
      const tab = cloneDeep(data.sets[index]);
      tab.title = `${tab.title}  [${newData.sets.length}]`;
      newData.sets.splice(index + 1, 0, tab);
      newData.current = tab.title;
      newData.currentIndex = index + 1;

      // setData(newData);
      // if (onChangeDataSet) {
      //   onChangeDataSet(newData);
      // }

      // if (onChangeCurrentSet) {
      //   onChangeCurrentSet(getRawSet(tab.params));
      // }
      notifyChangeTab(newData.current, newData.currentIndex, newData);
      //setCurrentTabId(tab.title);
    },
    [data, notifyChangeTab]
  );
  const moveTab = useCallback(
    (f, t) => {
      const newData = cloneDeep(data);
      const from = f + 1;
      const to = t + 1;

      newData.sets = move(newData.sets, from, to);

      const index = newData.sets.findIndex((set) => set.title === currentTabId);
      newData.currentIndex = index;
      const ref = newData.current;

      notifyChangeTab(ref, index, newData);
    },
    [currentTabId, data, notifyChangeTab]
  );

  const onChangeTabTitle = useCallback(
    (index, title) => {
      const newData = { ...data };

      if (data.sets[index].title === currentTabId) {
        newData.current = title;
      }

      newData.sets[index].title = title;

      notifyChangeTab(title, index, newData);
    },
    [currentTabId, data, notifyChangeTab]
  );

  const onTabClick = useCallback(
    (tabRef) => {
      const currentIndex = data.sets.findIndex((set) => set.title === tabRef);
      const newData = { ...data };
      newData.currentIndex = currentIndex;
      newData.current = tabRef;

      notifyChangeTab(tabRef, currentIndex, newData);
    },
    [data, notifyChangeTab]
  );

  useEffect(() => {
    explorer
      .get(
        `/request/params/${tenant}?excludeBasic=${excludeBasic}&includeParams=${includeParams}&compactDerived=${compactDerived}&includeNavigation=${includeNavigation}`,
        {
          headers: { id_token }
        }
      )
      .then((response) => {
        setParams(response.data);
      })
      .catch((error) => console.error(error));

    if (onChangeCurrentSet && data.sets) {
      onChangeCurrentSet(getRawSet(data.sets[data.currentIndex].params));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTable = useCallback(
    (tableIndex, tableData) => {
      const newSets = [...data.sets];
      newSets[tableIndex] = { ...newSets[tableIndex], params: tableData };

      const newData = {
        current: currentTabId,
        currentIndex: tableIndex,
        sets: newSets
      };

      callSaveTable(newData);
    },
    [callSaveTable, currentTabId, data.sets]
  );

  const tabsRefs = useMemo(
    () => new Set(data.sets?.map((tab) => tab.title)),
    [data.sets]
  );

  const pasteTab = useCallback(
    (full, tab) => {
      const newData = { ...data };
      const currentIndex = full ? data.currentIndex : data.sets.length;
      const ref =
        full || !tabsRefs.has(tab.title)
          ? tab.title
          : `${tab.title} [${currentIndex}]`;

      tab.title = ref;
      newData.currentIndex = currentIndex;
      newData.current = ref;

      if (full) newData.sets[currentIndex] = tab;
      else newData.sets.push(tab);

      notifyChangeTab(ref, currentIndex, newData);
    },
    [data, notifyChangeTab, tabsRefs]
  );

  const copyCollection = useCallback(() => {
    copy(JSON.stringify({ _ares: "paramsCollection", data }));
  }, [copy, data]);

  const pasteCollection = useCallback(
    (full, collection) => {
      const newData = full ? collection : cloneDeep(data);
      const ref = full
        ? collection.sets[collection.currentIndex].title
        : currentTabId;
      const currentIndex = data.sets.length - 1;

      if (!full) {
        collection.sets.forEach((tab, index) => {
          tab.title = tabsRefs.has(tab.title)
            ? `${tab.title} [${currentIndex + index}]`
            : tab.title;

          newData.sets.push(tab);
        });
      }

      notifyChangeTab(ref, currentIndex, newData);
    },
    [currentTabId, data, notifyChangeTab, tabsRefs]
  );

  const extraMenu = useMemo(() => {
    const clip = niceTry(() => JSON.parse(clipboard)) || {};
    const paramsSingleTab = clip._ares === "paramsSingleTab";
    const paramsCollection = clip._ares === "paramsCollection";

    return (
      <span>
        {allowCollection && (
          <FontAwesomeIcon icon={faPlusSquare} onClick={addTab} />
        )}
        <NavDropdown
          title={<FontAwesomeIcon icon={faClipboard} />}
          id="nav-dropdown"
          className={styles.extraDropIcon}
        >
          {allowCollection && (
            <Fragment>
              <NavDropdown.Item eventKey="4.2" onClick={copyCollection}>
                <FontAwesomeIcon icon={faCopy} className={styles.copyIcon} />
                Copy Collection
              </NavDropdown.Item>
              <NavDropdown.Divider />
            </Fragment>
          )}

          {allowCollection && (
            <NavDropdown.Item
              eventKey="4.1"
              disabled={!paramsSingleTab}
              className={!paramsSingleTab ? styles.disabledMenuItem : ""}
              onClick={() => pasteTab(false, clip.data)}
            >
              <FontAwesomeIcon icon={faPaste} className={styles.pasteIcon} />
              Paste and Append Tab
            </NavDropdown.Item>
          )}
          <NavDropdown.Item
            eventKey="4.1"
            disabled={!paramsSingleTab}
            className={!paramsSingleTab ? styles.disabledMenuItem : ""}
            onClick={() => pasteTab(true, clip.data)}
          >
            <FontAwesomeIcon icon={faPaste} className={styles.pasteIcon} />
            Paste and Substitute Tab
          </NavDropdown.Item>
          {allowCollection && (
            <Fragment>
              <NavDropdown.Divider />
              <NavDropdown.Item
                eventKey="4.3"
                disabled={!paramsCollection}
                className={!paramsCollection ? styles.disabledMenuItem : ""}
                onClick={() => pasteCollection(false, clip.data)}
              >
                <FontAwesomeIcon icon={faPaste} className={styles.pasteIcon} />
                Paste and Append Collection
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="4.3"
                disabled={!paramsCollection}
                className={!paramsCollection ? styles.disabledMenuItem : ""}
                onClick={() => pasteCollection(true, clip.data)}
              >
                <FontAwesomeIcon icon={faPaste} className={styles.pasteIcon} />
                Paste and Substitute Collection
              </NavDropdown.Item>
            </Fragment>
          )}
        </NavDropdown>
      </span>
    );
  }, [
    addTab,
    allowCollection,
    clipboard,
    copyCollection,
    pasteCollection,
    pasteTab
  ]);

  const tabs = useMemo(
    () =>
      data.sets?.map((tab, index) => (
        <TabPane
          key={tab.title}
          tab={
            <Tab
              data={tab}
              index={index}
              tabsRefs={tabsRefs}
              onChange={onChangeTabTitle}
              onDelete={deleteTab}
              onDuplicate={duplicateTab}
              allowCollection={allowCollection}
            />
          }
        >
          <ParamsTable
            tableIndex={index}
            onChangeTable={onChangeTable}
            params={params}
            data={tab.params}
          />
        </TabPane>
      )),
    [
      allowCollection,
      data.sets,
      deleteTab,
      duplicateTab,
      onChangeTabTitle,
      onChangeTable,
      params,
      tabsRefs
    ]
  );

  const handleUnfold = useCallback(() => {
    setFold((state) => !state);
  }, []);

  if (!params) return null;

  return (
    <Container
      title="Params"
      color="rgb(20, 3, 12)"
      foldable={true}
      folded={fold}
      header={
        <CloseView data={data} onTabClick={onTabClick} setOpen={handleUnfold} />
      }
      onFold={handleUnfold}
      style={style}
      help="params-box"
      extra={extra}
    >
      <DraggableTabs
        className={styles.draggableTabs}
        tabBarGutter={0}
        tabBarExtraContent={extraMenu}
        activeKey={currentTabId}
        moreIcon={<FontAwesomeIcon icon={faEllipsisH} />}
        onTabClick={onTabClick}
        destroyInactiveTabPane="rlt"
        moveTab={moveTab}
      >
        {tabs}
      </DraggableTabs>
    </Container>
  );
}

function CloseView(props) {
  const { data, onTabClick, setOpen } = props;

  const sets = useMemo(() => {
    if (!data || !data.sets) return [];

    return data.sets.map((set, index) => {
      const setDetailsTooltip = (props) => {
        return (
          <Tooltip
            id={`tooltip-params-details-${set.title}`}
            key={`tooltip-${index}`}
            {...props}
          >
            <div className={styles.paramsTooltip}>
              {Object.entries(set.params).map(([key, { value, active }]) => {
                if (!active) return null;
                return (
                  <div
                    className={styles.row}
                    key={`tooltip-body-${index}-${key}`}
                  >
                    <span className={styles.key}>{key}</span>
                    <span className={styles.value}>{value}</span>
                  </div>
                );
              })}
            </div>
          </Tooltip>
        );
      };
      return (
        <OverlayTrigger
          key={`overlay-${index}`}
          placement="bottom"
          delay={{ show: 500, hide: 100 }}
          overlay={setDetailsTooltip}
          trigger={["hover", "focus"]}
        >
          <Button
            className={`${styles.set} ${
              data.currentIndex === index ? styles.activeSet : ""
            }`}
            variant="outline-secondary"
            onClick={() => onTabClick(set.title)}
            onDoubleClick={() => setOpen(true)}
          >
            {set.title}
          </Button>
        </OverlayTrigger>
      );
    });
  }, [data, onTabClick, setOpen]);

  return (
    <div className={styles.scroller}>
      <ButtonGroup className={styles.sets} toggle="true">
        {sets}
      </ButtonGroup>
    </div>
  );
}

function Tab(props) {
  const {
    data,
    index,
    onChange,
    onDelete,
    onDuplicate,
    tabsRefs,
    allowCollection
  } = props;
  const [edit, setEdit] = useState(false);
  const [editError, setEditError] = useState(false);
  const [value, setValue] = useState(data.title);
  const { copy } = useClipboard();

  const onTabClick = useCallback((e) => {
    e.stopPropagation();
    setEdit(true);
  }, []);

  const handleUpdateValue = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const value = e.target.value;
      setValue(value);
      setEditError(!value || (tabsRefs.has(value) && value !== data.title));
    },
    [data.title, tabsRefs]
  );

  const handleEndEdit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (editError) return;
      onChange(index, value);
      setEdit(false);
    },
    [editError, index, onChange, value]
  );

  const handleDelete = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onDelete(index);
    },
    [index, onDelete]
  );

  const handleDuplicate = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onDuplicate(index);
    },
    [index, onDuplicate]
  );

  const handleCopyToClipboard = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      copy(JSON.stringify({ _ares: "paramsSingleTab", data }));
    },
    [copy, data]
  );

  const stopPropagation = useCallback((e) => e.stopPropagation(), []);

  if (edit) {
    return (
      <span className={styles.editTab}>
        <Form.Control
          type="text"
          placeholder=""
          min={0}
          value={value}
          onChange={handleUpdateValue}
          className={styles.field}
          isInvalid={editError}
          onClick={stopPropagation}
          onKeyDown={stopPropagation}
        />
        <Button
          variant={editError ? "outline-danger" : "outline-primary"}
          className={styles.btn}
          onClick={handleEndEdit}
        >
          OK
        </Button>
      </span>
    );
  }
  return (
    <span className={styles.paramsTab}>
      <span onDoubleClick={onTabClick} className={styles.title}>
        {value}
      </span>
      <span className={styles.icons}>
        {allowCollection && (
          <FontAwesomeIcon
            icon={faClone}
            className={styles.copyIcon}
            onClick={handleDuplicate}
          />
        )}
        <FontAwesomeIcon
          icon={faCopy}
          className={styles.copyToClipboardIcon}
          onClick={handleCopyToClipboard}
        />
        {index > 0 && (
          <FontAwesomeIcon
            icon={faTrashAlt}
            className={styles.deleteIcon}
            onClick={handleDelete}
          />
        )}
      </span>
    </span>
  );
}

function ParamsTable(props) {
  const { params, data, tableIndex, onChangeTable } = props;

  const onChangeRow = useCallback(
    (key, row) => {
      const newData = { ...data };
      newData[key] = row;
      onChangeTable(tableIndex, newData);
    },
    [data, onChangeTable, tableIndex]
  );

  return (
    <Table striped bordered variant="dark" className={styles.paramsTable}>
      <thead>
        <tr>
          <th className={styles.columnActive}>#</th>
          <th className={styles.columnParam}>Param</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {params.map((param) => {
          return (
            <ParamsRow
              key={param.key}
              param={param}
              data={data[param.key] || {}}
              onChange={onChangeRow}
            />
          );
        })}
      </tbody>
    </Table>
  );
}

function ParamsRow(props) {
  const { param, onChange, data } = props;

  const handleUpdateActive = useCallback(
    (e) => {
      const newData = { ...data };
      newData.active = !data.active;
      onChange(param.key, newData);
    },
    [data, onChange, param.key]
  );

  const handleUpdateValue = useCallback(
    (e) => {
      const newData = { ...data };
      newData.value = e.target.value;

      if (data.active === undefined && newData.value) {
        newData.active = true;
      }

      onChange(param.key, newData);
    },
    [data, onChange, param.key]
  );

  if (!param) return null;

  return (
    <tr className={data.active ? styles.rowActive : styles.rowInactive}>
      <td className={styles.columnActive}>
        <Form.Check
          type="checkbox"
          id={`active-${param.key}`}
          label=""
          checked={data.active || false}
          onChange={handleUpdateActive}
          className={styles.activeCheckbox}
        />
      </td>
      <td className={styles.columnParam}>{param.key}</td>
      <td>
        {param.key === "userId" ? (
          <InputPersonas
            value={data.value}
            onChange={handleUpdateValue}
            ignoreInvalid={true}
          />
        ) : (
          <ParamValue
            param={param}
            data={data}
            handleUpdateValue={handleUpdateValue}
          />
        )}
      </td>
    </tr>
  );
}

function ParamValue(props) {
  const { param, data, handleUpdateValue } = props;
  const [value, setValue] = useState(data.value || "");

  // useEffect(() => {
  //   if (!isEqual(data, value)) setValue(data.value);
  // }, [data, value]);

  const onChange = useCallback(
    (e) => {
      setValue(e.target.value);
      handleUpdateValue(e);
    },
    [handleUpdateValue]
  );

  if (param.values.length > 0) {
    return (
      <Form.Control
        as="select"
        value={data.value}
        onChange={handleUpdateValue}
        className={data.active ? styles.rowActive : styles.rowInactive}
      >
        <option></option>
        {param.values.map((val, index) => (
          <option key={`${index}-${val}`}>{val}</option>
        ))}
      </Form.Control>
    );
  }

  return (
    <Form.Control
      type={param.type === "number" ? "number" : "text"}
      placeholder=""
      min={0}
      value={value}
      onChange={onChange}
    />
  );
}
